import 'babel-polyfill';
import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import '../styles/main.scss';
import phoneIcon from '../img/Aeroseal_icon_phone.svg';
import emailIcon from '../img/Aeroseal_icon_email.svg';
import logo from '../img/AerosealBarrie_Logo_Reverse.svg';
import reverseLogo from '../img/AerosealBarrie_Logo-01.svg';
import Hamburger from './Hamburger';

const Layout = ({ children }) => (
	<div id="outer-wrap">
		<Helmet>
			<html lang="en" />
			<title>Aeroseal</title>
			<meta name="description" content="Aeroseal website." />
		</Helmet>
		<nav className="mobile-nav is-hidden-desktop is-hidden-tablet has-text-right">
			<Link to="/">
				<img
					src={reverseLogo}
					alt="Aeroseal Barrie logo"
					className="is-hidden-desktop is-hidden-tablet"
					style={{
						position: 'absolute',
						left: '1rem',
						height: '4rem',
					}}
				/>
			</Link>
			<Hamburger />
		</nav>
		<div id="page-wrap">
			<div style={{ position: 'fixed', width: '100%', zIndex: 10 }}>
				<nav className="top-nav is-muted is-hidden-mobile">
					<div className="container">
						<Link to="/">
							<img
								src={logo}
								alt="Aeroseal Barrie logo"
								style={{
									position: 'absolute',
									left: 0,
									height: '114px',
									backgroundColor: '#0291bf',
								}}
							/>
						</Link>
						<ul className="iconList">
							<li>
								<a href="tel:17052149610">
									<img src={phoneIcon} alt="phone" />
								</a>
							</li>
							<li>
								<a href="mailto:info@centralheatingontario.com">
									<img src={emailIcon} alt="email" />
								</a>
							</li>
						</ul>
					</div>
				</nav>
				<nav className="main-nav is-hidden-mobile">
					<div className="container">
						<ul>
							<li>
								<Link to="/residential">Residential</Link>
							</li>
							<li>
								<Link to="/commercial">Commercial</Link>
							</li>
							<li>
								<Link to="/faq">FAQs</Link>
							</li>
							<li>
								<Link to="/contact">Contact</Link>
							</li>
						</ul>
					</div>
				</nav>
			</div>
			{children}
			<footer className="top-nav is-dark">
				<div className="container has-text-centered">
					<p style={{ padding: '0.75rem', margin: 0 }}>
						<small>
							&copy; Copyright 2018 Aeroseal Barrie. All Rights Reserved.
						</small>
					</p>
				</div>
			</footer>
		</div>
	</div>
);

export default Layout;
