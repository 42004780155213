import React from 'react';
import { push as Menu } from 'react-burger-menu';
import { Link } from 'gatsby';
import '../styles/Hamburger.scss';

class Hamburger extends React.Component {
	render() {
		return (
			<div className="Hamburger">
				<Menu
					pageWrapId="page-wrap"
					outerContainerId="outer-wrap"
					width={'16rem'}
					right
					noOverlay
				>
					<Link to="/">Home</Link>
					<Link to="/residential">Residential</Link>
					<Link to="/commercial">Commercial</Link>
					<Link to="/faq">FAQs</Link>
					<Link to="/contact">Contact</Link>
				</Menu>
			</div>
		);
	}
}

export default Hamburger;
